import * as Sentry from '@sentry/browser'
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Link } from 'react-router-dom'
import styled from 'styled-components'

import { CaseStatus, CaseType } from '@debbie/common/dist/cases'
import { EmbedPlacement, getEmbedAreas } from '@debbie/common/dist/embeds'
import { caseFilterhash } from '@debbie/common/dist/insights'
import { TenantStatus } from '@debbie/common/dist/tenants'
import { h, c } from '@debbie/cortex/dist'
import { useInteractionInsightsTotals } from '@debbie/cortex/dist/interactions'
import { selectPausedCases } from '@debbie/cortex/dist/home/selectors'
import { selectTenant as selectMeTenant } from '@debbie/cortex/dist/me/selectors'
import { RootState } from '@debbie/cortex/dist/reducer'
import { selectTenant } from '@debbie/cortex/dist/tenant/selectors'
import {
  LeftNav,
  RightNav,
  ContentRight,
  AsyncComponent,
} from '@debbie/iris/dist/components'
import Changelog from '@debbie/iris/dist/components/Changelog'
import Archive from '@debbie/iris/dist/components/Icons/Archive'
import BarChart2 from '@debbie/iris/dist/components/Icons/BarChart2'
import BatteryCharging from '@debbie/iris/dist/components/Icons/BatteryCharging'
import Bookmark from '@debbie/iris/dist/components/Icons/Bookmark'
import Briefcase from '@debbie/iris/dist/components/Icons/Briefcase'
import Database from '@debbie/iris/dist/components/Icons/Database'
import Heart from '@debbie/iris/dist/components/Icons/Heart'
import HomeIcon from '@debbie/iris/dist/components/Icons/Home'
import Mail from '@debbie/iris/dist/components/Icons/Mail'
import Phone from '@debbie/iris/dist/components/Icons/Phone'
import Send from '@debbie/iris/dist/components/Icons/Send'
import TrendingUp from '@debbie/iris/dist/components/Icons/TrendingUp'
import Users from '@debbie/iris/dist/components/Icons/Users'
import THEME from '@debbie/iris/dist/theme'

import logo from './assets/images/logoneg.svg'
import RedirectTo from './components/RedirectTo'
import { Error } from './pages/Error'
import { Loader } from './pages/Loader'

const Journalizer = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/Journalizer').then((module) => module.default),
  Sentry.captureException,
)

const OutboundCall = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/OutboundCall').then((module) => module.default),
  Sentry.captureException,
)

const Outbox = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/Outbox').then(async (module) => module.default),
  Sentry.captureException,
)

const Home = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/Home').then((module) => module.default),
  Sentry.captureException,
)

const Changelogs = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/Changelogs').then((module) => module.default),
  Sentry.captureException,
)

const Cases = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/cases/Cases').then((module) => module.default),
  Sentry.captureException,
)

const Case = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/cases/Case').then((module) => module.default),
  Sentry.captureException,
)

const Imports = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/imports/Imports').then((module) => module.default),
  Sentry.captureException,
)

const Import = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/imports/Import').then((module) => module.default),
  Sentry.captureException,
)

const Reports = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/Reports').then((module) => module.default),
  Sentry.captureException,
)

const Creditors = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/creditors/Creditors').then((module) => module.default),
  Sentry.captureException,
)

const EmbedsPage = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/EmbedsPage').then((module) => module.default),
  Sentry.captureException,
)

const DesignSystem = AsyncComponent(
  Loader,
  Error,
  () =>
    import('@debbie/iris/dist/components/DesignSystem').then(
      (module) => module.default,
    ),
  Sentry.captureException,
)

const Lighthouse = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/Lighthouse').then((module) => module.default),
  Sentry.captureException,
)

const CustomerCare = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/CustomerCare').then((module) => module.default),
  Sentry.captureException,
)

const TodoQueue = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/TodoQueue').then((module) => module.default),
  Sentry.captureException,
)

const CreateCreditor = AsyncComponent(
  Loader,
  Error,
  () =>
    import('./pages/creditors/creditor/create/CreateCreditor').then(
      (module) => module.default,
    ),
  Sentry.captureException,
)

const Creditor = AsyncComponent(
  Loader,
  Error,
  () =>
    import('./pages/creditors/creditor/Creditor').then(
      (module) => module.default,
    ),
  Sentry.captureException,
)

const Settings = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/settings/Settings').then((module) => module.default),
  Sentry.captureException,
)

const User = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/User').then((module) => module.default),
  Sentry.captureException,
)

const TenantBillingInvoice = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/TenantBillingInvoice').then((module) => module.default),
  Sentry.captureException,
)

const TenantBilling = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/TenantBilling').then((module) => module.default),
  Sentry.captureException,
)

const Developers = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/Developers').then((module) => module.default),
  Sentry.captureException,
)

const Economy = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/economy/Economy').then((module) => module.default),
  Sentry.captureException,
)

const ReturnLetters = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/ReturnLetters').then((module) => module.default),
  Sentry.captureException,
)

const CreateCase = AsyncComponent(
  Loader,
  Error,
  () =>
    import('./pages/cases/create/CreateCase').then((module) => module.default),
  Sentry.captureException,
)

const WorkflowEditor = AsyncComponent(
  Loader,
  Error,
  () =>
    import('./pages/settings/WorkflowEditor').then((module) => module.default),
  Sentry.captureException,
)

const EConomicMapper = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/EConomicMapper').then((module) => module.default),
  Sentry.captureException,
)

const ConnectConnections = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/connect/Connections').then((module) => module.default),
  Sentry.captureException,
)

const ConnectConnection = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/connect/Connection').then((module) => module.default),
  Sentry.captureException,
)

const Product = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/products/Product').then((module) => module.default),
  Sentry.captureException,
)

const Products = AsyncComponent(
  Loader,
  Error,
  () => import('./pages/products/Products').then((module) => module.default),
  Sentry.captureException,
)

const LoggedInView = () => {
  const lang = useSelector((s: RootState) => s.lang)
  const leftNavOpen = useSelector((s: RootState) => s.leftNavOpen)
  const redirectTo = useSelector((s: RootState) => s.redirectTo)
  const rightNavOpen = useSelector((s: RootState) => s.rightNavOpen)
  const pendingCases = useSelector(
    (s: RootState) =>
      s.caseList[
        caseFilterhash({
          caseTypes: [CaseType.TENANT],
          caseStatuses: [CaseStatus.PENDING],
        })
      ]?.data,
  )
  const pausedCases = useSelector(selectPausedCases)
  const meTenant = useSelector(selectMeTenant)
  const tenant = useSelector(selectTenant)
  const interactionTotalsData = useInteractionInsightsTotals()

  const interactionStats = h.hasData(interactionTotalsData)
    ? interactionTotalsData.data
    : null

  const setMobileView = (initial: boolean) => () => {
    const media = window.matchMedia(THEME.MEDIA_QUERY_MOBILE)
    if (media.matches && leftNavOpen) {
      c.local.toggleLeftNav()
    }
    if (!media.matches && !leftNavOpen && !initial) {
      c.local.toggleLeftNav()
    }
  }

  useEffect(() => {
    c.loadCaseWorkerAppData()

    setMobileView(true)()
    window.addEventListener('resize', setMobileView(false))

    return () => {
      window.removeEventListener('resize', setMobileView(false))
    }
  }, [])

  useEffect(() => {
    if (meTenant) {
      const status =
        meTenant.status !== TenantStatus.Active
          ? `${lang.tenantStatus[meTenant.status]}: `
          : ''
      document.title = `${status}${meTenant.name} - Debbie`
    }
  }, [meTenant])

  if (meTenant && meTenant.status === 'DISABLED')
    return (
      <Blocked>
        <div className="content">
          <img src={logo} className="logo" alt="logo" width="100%" />
          <h1>Din konto er inaktiv</h1>
          <p
            style={{
              fontSize: '0.8em',
            }}
          >
            <Link to="/account-selector">Vælg mellem konti</Link>
          </p>
        </div>
      </Blocked>
    )

  const embedAreas = tenant
    ? getEmbedAreas(tenant.embeds, EmbedPlacement.CaseworkerWebMenuItem)
    : []

  const embedAreaLinks = embedAreas.length > 0 && {
    text: lang.embeds.caseworkerNavMenuItemCategory,
    exact: false,
    links: embedAreas.map((embedArea) => ({
      text: embedArea.title,
      path: `/embeds/${embedArea.embedId}`,
      icon: <Bookmark />,
    })),
  }

  return (
    <>
      <LeftNav
        logo={logo}
        links={[
          {
            text: lang.navigation.overview,
            path: '/',
            links: [
              {
                text: lang.navigation.home,
                icon: <HomeIcon />,
                path: '/',
                count:
                  (pendingCases?.cases ? pendingCases?.cases.length : 0) +
                  (pausedCases &&
                    pausedCases.reduce((acc, item) => item.count + acc, 0)),
              },
              {
                text: lang.navigation.cases,
                icon: <Briefcase />,
                path: '/cases',
              },
            ],
          },
          {
            text: lang.navigation.economy,
            exact: false,
            path: '/economy/deposits',
            links: [
              {
                text: lang.navigation.economyDeposits,
                path: '/economy/deposits',
                icon: <TrendingUp />,
                exact: false,
              },
              {
                text: lang.navigation.economyBilling,
                path: '/economy/billing',
                icon: <BatteryCharging />,
              },
            ],
          },
          {
            text: lang.navigation.content,
            path: '/creditors',
            exact: false,
            links: [
              {
                text: lang.navigation.creditors,
                path: '/creditors',
                icon: <Users />,
              },
              {
                text: lang.navigation.reports,
                path: '/reports',
                icon: <BarChart2 />,
              },
              {
                text: lang.navigation.import,
                path: '/imports',
                icon: <Database />,
              },
            ],
          },
          {
            text: lang.navigation.communication,
            path: '/journalizer',
            links: [
              {
                text: <span>{lang.navigation.outboundCalls}</span>,
                icon: <Phone />,
                path: '/outbound',
              },
              {
                text: lang.navigation.journalizer,
                icon: <Archive />,
                path: '/journalizer',
                count: interactionStats ? interactionStats.journalizer : 0,
              },
              {
                text: lang.navigation.outbox,
                icon: <Send />,
                path: '/outbox',
                count: interactionStats ? interactionStats.outbox : 0,
              },
            ].concat(
              tenant &&
                [
                  'd85b3ab3-dc9f-431f-b6dc-13cb71e0edc9', // Return letter tenant
                  '927712af-bb7b-4b6b-8e0a-3c323436df16', // Nuna Law tenant
                ].includes(tenant.id)
                ? [
                    {
                      text: lang.navigation.returnLetters,
                      icon: <Mail />,
                      path: '/return-letters',
                    },
                  ]
                : [],
            ),
          },
          {
            text: lang.navigation.customerCare,
            links: [
              {
                text: lang.navigation.support,
                icon: <Heart />,
                exact: false,
                external: true,
                path: 'https://debbie.zendesk.com/hc/da/requests/new',
              },
            ],
          },
        ].concat(embedAreaLinks || [])}
      />
      {rightNavOpen && <RightNav />}
      {redirectTo.to !== '' && <RedirectTo {...redirectTo} />}

      <Switch>
        <ContentRight rightNavOpen={rightNavOpen} leftNavOpen={leftNavOpen}>
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="/changelogs">
            <Changelogs />
          </Route>
          <Route exact path="/journalizer">
            <Journalizer />
          </Route>
          <Route exact path="/outbound">
            <OutboundCall />
          </Route>
          <Route exact path="/outbound/:priority/:id">
            <OutboundCall />
          </Route>
          <Route exact path="/outbox">
            <Outbox />
          </Route>
          <Route exact path="/imports">
            <Imports />
          </Route>
          <Route path="/imports/:importId">
            <Import />
          </Route>
          <Route path="/reports">
            <Reports />
          </Route>
          <Route exact path="/creditors">
            <Creditors />
          </Route>
          <Route exact path="/create-creditor">
            <CreateCreditor />
          </Route>
          <Route path="/create-creditor/:creditorId/:state">
            <CreateCreditor />
          </Route>
          <Route path="/creditors/:id">
            <Creditor />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
          <Route path="/user">
            <User />
          </Route>
          <Route path="/tenant-billing" exact>
            <TenantBilling />
          </Route>
          <Route path="/tenant-billing/:invoiceId">
            <TenantBillingInvoice />
          </Route>
          <Route path="/developers">
            <Developers />
          </Route>
          <Route exact path="/workflows/:workflowId">
            <WorkflowEditor />
          </Route>
          <Route path="/economy">
            <Economy />
          </Route>
          <Route exact path="/cases">
            <Cases />
          </Route>
          <Route exact path="/create-case">
            <CreateCase />
          </Route>
          <Route exact path="/create-case/:caseId/:slug">
            <CreateCase />
          </Route>
          <Route exact path="/cases/:ids">
            <Case />
          </Route>
          <Route exact path="/return-letters">
            <ReturnLetters />
          </Route>
          <Route exact path="/embeds/:id">
            <EmbedsPage />
          </Route>
          <Route exact path="/design-system">
            <DesignSystem />
          </Route>
          <Route exact path="/lighthouse">
            <Lighthouse />
          </Route>
          <Route exact path="/customer-care">
            <CustomerCare />
          </Route>
          <Route exact path="/todo-queue/:updateTypeId/:id">
            <TodoQueue />
          </Route>
          <Route exact path="/todo-queue/:updateTypeId">
            <TodoQueue />
          </Route>
          <Route exact path="/e-conomic-mapper/:creditorId">
            <EConomicMapper />
          </Route>
          <Route exact path="/connect/connections">
            <ConnectConnections />
          </Route>
          <Route exact path="/connect/connections/:id">
            <ConnectConnection />
          </Route>
          <Route exact path="/products/:id">
            <Product />
          </Route>
          <Route exact path="/products">
            <Products />
          </Route>
          <Changelog />
        </ContentRight>
      </Switch>
    </>
  )
}

export default LoggedInView

const Blocked = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: ${THEME.COLOR_SECONDARY};

  .content {
    text-align: center;
    width: 100%;
    max-width: 1200px;
    padding: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    h1 {
      color: ${THEME.COLOR_ACCENT};
      font-size: 1.1em;
    }

    .logo {
      width: 250px;
      margin: 1em 0;
    }
  }
`
