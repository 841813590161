import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'

import { c } from '@debbie/cortex/dist'

type Props = {
  to: string
  replace: boolean
}

const RedirectTo = (props: Props) => {
  const to = props.to
  const replace = props.replace

  useEffect(() => {
    c.redirect.redirect('')
  }, [])

  return to && <Redirect push={!replace} to={to} />
}

export default RedirectTo
